import React from 'react'
import Container from './container'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Divider, Flex, Heading, HStack, Icon, Link, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react'
import { FaChevronRight } from 'react-icons/fa'
import Img from 'gatsby-image'

const FeaturedList = ({featured, ...rest}) => {

	const settings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 6000,
		cssEase: 'ease',
		
	}

	return (
		<Box {...rest} py={{base: 16, md: 32}}>
			<Container>
				<SimpleGrid columns={{base: 1, lg: 3}} >
					{featured.map((item, index) => (
						<Flex key={index} flexDirection={{base: 'column', lg: 'row'}}>
						<FeaturedItem {...item} />
						{index < 2 && (
							<ResponsiveDivider />
						) }
						</Flex>
					))}
				</SimpleGrid>
			</Container>
		</Box>
	)

}

export const FeaturedItem = ({icon, page}) => (

	<Box textAlign="center" p={{base: 0, md: 8}}>
		<Img fixed={icon.fixed} />
		<Heading fontWeight="bold" pt={{base: 2, md: 4}} fontSize={{base: "2xl", md: "4xl"}} >
			{page[0].shortTitle}
		</Heading>
		<Text py={{base: 2, md: 4}} fontSize={{base: "lg", md: "2xl"}}  color="octopus.500" >
			{page[0].description.description}
		</Text>
		<HStack justifyContent="center" color="brand.500" fontSize={{base: "md", md: "lg"}}>
			<Link 
				fontWeight="bold"
				textTransform="uppercase"
				letterSpacing="wide"
				as={GatsbyLink} 
				to={`/servicos/${page[0].slug}`}
			>
				Saiba mais
			</Link>
			<Icon as={FaChevronRight} />
		</HStack>
	</Box>

)

const ResponsiveDivider = () => (
	<Divider 
		borderColor="octopus.100" orientation={useBreakpointValue({base: "horizontal", md: "vertical"})} 
		width={useBreakpointValue({base: "40%", md: 0})}
		height={useBreakpointValue({base: 0, md: "40%"})}
		m="auto"
		my={{base: 8, md: "auto"}}
	/>
)


export default FeaturedList