import { Box, Center, Circle, Flex, Heading, HStack, Icon, Square, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { FaQuoteLeft, FaStar } from 'react-icons/fa'
import AnimatedIcon from './animated-icon'
import Container from './container'
import SlickSlider from './slick-slider'
import Img from 'gatsby-image'

const Reviews = ({data: {reviews, heading }, ...rest}) => {

	const settings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		speed: 600,
		autoplaySpeed: 6000,
		dots: true
	}

	return(
		<Box 
			{...rest}
			py={{base: 16, md: 32}}
			overflow="hidden"
			position="relative"
			background="radial-gradient(circle, rgba(44,169,223,0.1) 0%, rgba(255,255,255,0) 30%)"
			
		>
			<Container>
				<Heading
					as="h2" 
					fontSize={{base: "3xl", md: "5xl"}}
					fontWeight="extrabold"
					textAlign="center"
					pb={{base: 8, md: 16}}
				>
					{heading}
				</Heading>
			</Container>
			<Box
				height="100%"
			>
				<Container>
					<SlickSlider settings={settings} >
						{reviews.map((item,index) => (
								<ReviewItem {...item} key={index} />
							))}
					</SlickSlider>
				</Container>
				<AnimatedIcon top="50%" opacity="0.5" left="50%" transform="translate(-50%, -50%)" boxSize={{base: "36rem", md: "96rem"}} />
			</Box>
		</Box>
	)

}

const ReviewItem = ({author, avatar, message, rate, ...rest}) => (
	<Box {...rest}>
		<Flex 
			alignItems="center" 
			flexDirection={{base: "column", md: "row"}} 
			mx="auto" 
			maxWidth={{base: '90%', md: '60%'}} 
		>
			<Box zIndex={{base: "base", md: "docked"}}>
				<Box 
					p={{base: 4, md: 8}}
					borderRadius={8}
					mr={{base: 0, md: -12}}
					backgroundColor="white"
					position="relative"
					textAlign={{base: "center", md:"left"}}
				>
					<Icon 
						top={{base: 2, md: -8}} 
						left={{base: 2, md: -8}}  
						position="absolute" 
						as={FaQuoteLeft} 
						color="brand.500" 
						fontSize={{base: 16, md: 52}} 
					/>
					<Text 
						fontSize={{base: "md", md: "lg"}} 
						pb={{base: 2, md: 4}} 
						color="octopus.700"
					>
						{message.message}
					</Text>
					<HStack display="inline-flex">
						{Array(5)
							.fill("")
							.map((_, index) => (
								<Icon key={`rate-star-${index+1}`} as={FaStar} color={index < rate ? "ocean.700" : "ocean.50"} fontSize={{base: 16, md: 32}} />
							))}
					</HStack>
				</Box>
			</Box>
			<Square 
				boxSize={{base: 24, md: 64}} 
				backgroundColor="brand.500" 
				borderRadius={8} 
				p={{base: 2, md: 8}}
				mt={{base: 2, md: 0}}
				zIndex={{base: "docked", md: "base"}}
			>
				<Center flexDirection="column">
					<Circle 
						boxSize={useBreakpointValue({base: 8, md: 16})} 
						mx="auto" 
						borderWidth={3} 
						borderColor="white" 
						overflow="hidden"
					>
						<Img fixed={avatar.fixed} objectFit="cover" />
					</Circle>
					<Text 
						textAlign="center" 
						color="white" 
						fontWeight="bold" 
						fontSize={{base: "xs", md: "lg"}}
						lineHeight="shorter"
					>
						{author}
					</Text>
				</Center>
			</Square>
		</Flex>
	</Box>

)

export default Reviews