import { Box } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import CTASection from '../components/cta'
import FeaturedList from '../components/featured-list'
import Layout from '../components/layout'
import PerkList from '../components/perk-list'
import Reviews from '../components/reviews'
import SEO from '../components/seo'
import Img from 'gatsby-image'

export const query = graphql`
	query {
		contentfulPage(slug: {eq: "home"}) {
			title,
			metadescription {
				metadescription
			}
			...homePageData
		}
	}
`


export const Home = ({data, ...rest}) => {

	const home = data.contentfulPage.template

	return (
		<Layout>
			<SEO 
				title={data.contentfulPage.title}
				description={data.contentfulPage.metadescription.metadescription}
			/>
			<CTASection hero data={home.hero} pt={{base: 48, md: "16rem!important"}} />
			<FeaturedList featured={home.featured} />
			<CTASection data={home.cta} >
				<Box ml={{base: -16, md: 0}} width={{base: "600px", md: "100%"}}>
					<Img fluid={home.cta.media.fluid} />
				</Box>
			</CTASection>
			<PerkList data={{heading: home.perksHeading, perks: home.perks}} />
			<Reviews data={{heading: home.reviewsHeading, reviews: home.reviews}}  />
		</Layout>
	)
}

export default Home